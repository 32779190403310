<template>
    <default-template
        title="温熱・省エネ設備機器ポータルサイト(住宅版)"
        :is-visible-public="isOrganization"
        :is-visible-logout="true"
        :is-visible-pass-update="true"
        :is-visible-jigyosya-login="false"
        :is-house="true"
        :is-visible-user-header="isOrganization"
        :is-visible-administrator-header="isAdministrator"
    >
        <div class="bl_underHeader ly_underHeader">
            <h2 class="el_lv2Heading">ハイブリッド給湯機一覧</h2>
            <app-button
                v-if="isOrganization"
                name="新規登録"
                @click="upload"
                ref="jest-organization-hybrid-upload"
                class="inline wd_200px"
            />
            <app-button
                v-if="isAdministrator"
                name="建研用データダウンロード"
                @click="adminDownload"
                ref="jest-admin-hybrid-download"
                class="inline"
            />
            <app-button
                v-if="isOrganization"
                name="機器リストダウンロード"
                @click="download"
                ref="jest-public-hybrid-download"
                class="inline"
            />
        </div>
        <error-list class="notice" />
        <div class="bl_searchBox_outer">
            <p class="bl_searchBox_outer_msg">検索条件を入力してください。</p>
            <hr class="hp_flexspacer"/>
            <div class="bl_searchBox ly_searchBox">
                <div class="uq_flexcolumn">
                    <div class="bl_input_container bl_input_container_column">
                        <div
                            v-if="isAdministrator"
                            class="bl_input_container"
                        >
                            <app-label
                                name="brand_name"
                                label="ブランド事業者名"
                            />
                            <app-select
                                v-model="brand_name"
                                :label="'選択してください'"
                                :vmodel-label="brand_name"
                                ref="jest-public-brand-name"
                            >
                                <app-select-option
                                    :value="''"
                                    v-model="brand_name"
                                >
                                    選択してください
                                </app-select-option>
                                <app-select-option
                                    v-for="(item, index) in brandNames"
                                    :key="index"
                                    :value="item"
                                    v-model="brand_name"
                                >
                                    {{item}}
                                </app-select-option>
                            </app-select>
                        </div>
                        <app-text
                            type="date"
                            name="release_date_from"
                            ref="jest-release-date-from"
                            v-model="release_date_from"
                            label="発売予定年月日"
                            class="formrow"
                            unit="以降"
                        />
                        <app-text
                            type="date"
                            name="publish_date_from"
                            ref="jest-release-date-from"
                            v-model="publish_date_from"
                            label="情報公開予定年月日"
                            class="formrow"
                            unit="以降"
                        />
                        <div class="bl_input_container formrow">
                            <app-label
                                name="status"
                                label="ステータス"
                            />
                            <app-select
                                v-model="status"
                                :label="'選択してください'"
                                :vmodel-label="vModelLabel"
                                ref="jest-public-brand-name"
                            >
                                <app-select-option
                                    :value="''"
                                    v-model="status"
                                >
                                    選択してください
                                </app-select-option>
                                <app-select-option
                                    v-for="(item, index) in devicesStatus"
                                    :key="index"
                                    :value="item.english"
                                    v-model="status"
                                >
                                    {{item.japanese}}
                                </app-select-option>
                            </app-select>
                        </div>
                    </div>
                    <div
                        v-if="isAdministrator"
                        class="bl_input_container uq_date"
                    >
                        <app-label
                            label="期間の指定"
                            class="rowlabel grid1"
                        />
                        <div class="rowcontents grid2">
                            <app-radio
                                v-model="date_type"
                                value=""
                                label="指定しない"
                            />
                            <app-radio
                                v-model="date_type"
                                value="created_at"
                                label="登録日"
                            />
                            <app-radio
                                v-model="date_type"
                                value="updated_at"
                                label="更新日"
                            />
                            <app-radio
                                v-model="date_type"
                                value="approved_at"
                                label="承認日"
                            />
                        </div>
                    </div>
                    <div 
                        v-if="isAdministrator"
                        class="bl_input_container uq_date"
                    >
                        <label
                            class="rowlabel grid1 el_label"
                        >
                            <span v-if="date_type == 'created_at'">登録日</span>
                            <span v-else-if="date_type == 'updated_at'">更新日</span>
                            <span v-else-if="date_type == 'approved_at'">承認日</span>
                        </label>
                        <div class="rowcontents grid2">
                            <app-text-box
                                name="start_date"
                                v-model="start_date"
                                label=""
                                class="formrow"
                                type="date"
                                ref="jest-orgnaization-start-date"
                                :disabled="isSet(date_type) === false"
                            />
                            ～
                            <app-text-box
                                name="finish_date"
                                v-model="finish_date"
                                label=""
                                class="formrow"
                                type="date"
                                ref="jest-orgnaization-finish-date"
                                :disabled="isSet(date_type) === false"
                            />
                        </div>
                    </div>
                </div>
                    
                <div class="uq_flexcolumn">
                    <div  class="bl_input_container bl_input_container_column">
                        <app-text
                            name="pu_number"
                            ref="jest-pu-number"
                            v-model="pu_number"
                            label="ヒートポンプユニット番号"
                            class="formrow"
                        />
                        <app-text
                            name="tu_number"
                            ref="jest-tu-number"
                            v-model="tu_number"
                            label="貯湯ユニット品番"
                            class="formrow"
                        />
                        <app-text
                            name="bb_number"
                            v-model="bb_number"
                            label="補助熱源機品番"
                            class="formrow"
                        />
                        <app-text
                            name="certificate_number"
                            v-model="certificate_number"
                            label="成績証明書番号等"
                            class="formrow"
                        />
                    </div>
                </div>
                <div class="uq_flex_end">
                    <app-button
                        name="検索"
                        @click="search"
                        ref="jest-organization-hybrid-search"
                        class="inline wd_100px uq_align_bottom"
                    />
                </div>
            </div>
        </div>
        <p v-if="total != 0">{{total}}件中{{start}}～{{end}}件表示</p>
        <table class="lists_table">
            <thead>
                <tr>
                    <th class="lists_table_pu_number"><span>ヒートポンプ</span><span>ユニット番号</span></th>
                    <th class="lists_table_tu_number"><span>貯湯ユニット</span><span>品番</span></th>
                    <th class="lists_table_bb_number"><span>補助熱源機</span><span>品番</span></th>
                    <th>ステータス</th>
                    <th class="lists_table_certificate_number"><span>成績証明書</span><span>番号</span></th>
                    <th class="lists_table_performanceValidationCategory"><span>性能確認</span><span>区分</span></th>
                    <th class="lists_table_releaseYearMonth">情報公開日</th>
                    <th class="lists_table_releaseYearMonth">発売予定日</th>
                    <th class="lists_table_releaseYearMonth">最終更新日</th>
                    <th></th>
                </tr>
            </thead>
            <tr
                v-for="item in hybridWaterHeaters"
                :key="'hybridWaterHeaters' + item.id"
                :ref="'hybridWaterHeaters' + item.id"
                class="deviceLine"
                @mouseover="over(item.id)"
                @mouseleave="leave()"
            >
                <td>{{item.pu_number}}</td>
                <td>{{item.tu_number}}</td>
                <td>{{item.bb_number}}</td>
                <td>{{master.devices_status[item.status].japanese}}</td>
                <td>{{item.certificate_number}}</td>
                <td>{{performanceValidationCategory(item)}}</td>
                <td>{{date(item.publish_date)}}</td>
                <td>{{date(item.release_date)}}</td>
                <td>{{date(item.updated_at)}}</td>
                <td class="td-fixed lists_table_relative">
                    <div
                        v-if="hover(item.id)"
                        class="buttons lists_table_hover"
                        :ref="'jest-organization-hybrid' + item.id"
                    >
                        <app-button
                            name="詳細"
                            @click="toDetail(item)"
                            :ref="'jest-organization-hybrid-to-detail' + item.id"
                            class="inline wd_80px"
                        />
                        <app-button
                            v-if="isOrganization"
                            name="申請"
                            @click="toPublish(item)"
                            :disabled="isProhibitedToPublish(item)"
                            :ref="'jest-organization-hybrid-to-publish' + item.id"
                            class="inline wd_80px"
                        />
                        <app-button
                            name="削除"
                            @click="toDelete(item)"
                            class="delete inline wd_80px"
                            :disabled="isProhibitedToDelete(item)"
                            :ref="'jest-organization-hybrid-to-delete' + item.id"
                        />
                        <app-button
                            v-if="isOrganization"
                            name="取下"
                            @click="toCancel(item)"
                            class="delete inline wd_80px"
                            :disabled="isProhibitedToCancel(item)"
                            :ref="'jest-organization-hybrid-to-cancel' + item.id"
                        />
                        <app-button
                            v-if="isOrganization"
                            name="取下申請"
                            :disabled="isProhibitedToPublish(item)"
                            @click="toPublishAndCancel(item)"
                            class="delete inline wd_80px"
                            :ref="'jest-organization-hybrid-publish-cancel' + item.id"
                        />
                        <app-button
                            v-if="isAdministrator"
                            name="承認"
                            :disabled="isProhibitedToApprove(item)"
                            @click="toApprove(item)"
                            :ref="'jest-admin-hybrid-to-approve' + item.id"
                            class="inline wd_80px"
                        />
                    </div>
                </td>
            </tr>
        </table>
        <list-pager
            :page="page"
            :page-count="pageCount"
            @page-change="pageChange"
        />
        <!-- CSVダウンロード用に実在する要素が必要 -->
        <div
            id="invisible"
            v-show="false"
        />
    </default-template>
</template>

<script>
/**
 * P150 ハイブリッド給湯器一覧（事業者）
 * P240 ハイブリッド給湯器一覧（管理者）
 *
 * [算出プロパティ]
 * date...文字列表記の日付を受け取り、日本語表記の年月日にする
 * hover...指定されたidの行がホバーされているか否か
 * performanceValidationCategory...性能確認区分か読み込み遅延時(この箇所だけ起こる)に空白を返す
 * pageCount...リストの件数の合計から算出されるリストのページ数を返す
 * isProhibitedToPublish...機器の申請・取下申請の可否を返す（事業者のみ）（機器が公開申請前かつ機器を表示している事業者がブランド事業者である場合false）
 * isProhibitedToDelete...機器の削除の可否を返す（事業者としての削除条件または管理者としての削除条件を満たしていればfalse）
 * isOrganizationProhibitedToDelete...機器の削除の可否を返す（事業者）
 *      （事業者の場合、機器が公開申請前または「機器が公開申請済みかつ機器を表示している事業者がブランド事業者である」場合false）
 * isAdministratorProhibitedToDelete...機器の削除の可否を返す（管理者）
 *      （管理者の場合、公開申請済、公開承認済、一般公開中、取下公開中のいずれかのステータスの場合false）
 * isProhibitedToCancel...機器の取下の可否を返す（事業者のみ）
 *      （ステータスが公開申請済・公開承認済・一般公開中のいずれかかつ機器を表示している事業者がブランド事業者であり、さらにまだ取下げフラグがfalseである場合はfalse）
 * isProhibitedToApprove...機器の承認の可否を返す（管理者のみ）（ステータスが公開申請済である場合false）
 * isBrandUser...機器のブランド事業者IDがログイン中の事業者IDと一致するか否か
 * isOrganization...事業者ページか否か
 * isAdministrator...管理者ページか否か
 *
 * [イベント処理]
 * search...機器の検索を実行
 * adminDownload...建研用ダウンロードを実行（管理者のみ）
 * upload...アップロード画面に遷移（事業者のみ）
 * toDetail...機器の詳細画面に遷移
 * toDelete...機器の削除画面に遷移
 * toPublish...機器の公開申請画面に遷移（事業者のみ）
 * toCancel...機器の取下画面に遷移（事業者のみ）
 * toPublishAndCancel...機器の取下申請画面に遷移（事業者のみ）
 * toApprove...機器の承認画面に遷移（管理者のみ）
 * over...マウスホバーした箇所のidをdataに保存
 * leave...マウスホバーした箇所のid情報を削除
 * pageChange...機器リストのページの切り替え
 */
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import { cloneDeep } from 'lodash'
import master from '@/mixins/master'
import { mapComputedProperties, move, isSet, pageContentCount } from '@/util'
import ErrorList from '@/components/molecules/ErrorList'
import ListPager from '@/components/molecules/ListPager'

export default {
    name:'HybridWaterHeaterList',
    components:{
        ErrorList,
        ListPager,
    },
    mixins:[master],
    data(){
        return {
            hovered:null
        }
    },
    computed:{
        ...mapGetters({
            hybridWaterHeaters:'hybridWaterHeaters/hybridWaterHeaters',
            lastUpdate:'hybridWaterHeaters/lastUpdate',
            total:'hybridWaterHeaters/total',
            data:'hybridWaterHeaters/searchHybridWaterHeatersData',
            organization:'auth/organization',
            brandNames:'hybridWaterHeaters/brandNames',
        }),
        ...mapComputedProperties([
            'brand_name',
            'release_date_from',
            'publish_date_from',
            'include_expired',
            'pu_number',
            'tu_number',
            'bb_number',
            'certificate_number',
            'status',
            'page',
            'start_date',
            'finish_date',
            'date_type',
        ]),
        /**
         * 文字列表記の日付を受け取り、日本語表記の年月日にする
         */
        date(){
            return function (date) {
                return date ? moment(date).format('YYYY年MM月DD日') : '-'
            }
        },
        /**
         * 指定されたidの行がホバーされているか否か
         */
        hover(){
            return function(id) {
                return this.hovered === id
            }
        },
        /**
         * 性能確認区分か読み込み遅延時(この箇所だけ起こる)に空白を返す
         */
        performanceValidationCategory(){
            return function (item) {
                return item.hybrid_water_heater_certificate ? item.hybrid_water_heater_certificate.performance_validation_category : ''
            }
        },
        /**
         * リストの件数の合計から算出されるリストのページ数を返す
         */
        pageCount(){
            return Math.ceil(this.total / pageContentCount)
        },
        /**
         * 機器の申請・取下申請の可否を返す（事業者のみ）
         * （機器が公開申請前かつ機器を表示している事業者がブランド事業者である場合false）
         */
        isProhibitedToPublish(){
            return function(item) {
                return !this.isOrganization || item.status !== 1 || !this.isBrandUser(item)
            }
        },
        /**
         * 機器の削除の可否を返す
         * （事業者としての削除条件または管理者としての削除条件を満たしていればfalse）
         */
        isProhibitedToDelete(){
            return function(item) {
                return this.isOrganizationProhibitedToDelete(item) && this.isAdministratorProhibitedToDelete(item)
            }
        },
        /**
         * 機器の削除の可否を返す（事業者）
         * （事業者の場合、機器が公開申請前または「機器が公開申請済みかつ機器を表示している事業者がブランド事業者である」場合false）
         */
        isOrganizationProhibitedToDelete(){
            return function(item) {
                return !this.isOrganization || (item.status !== 1 && (item.status !== 2 || !this.isBrandUser(item)))
            }
        },
        /**
         * 機器の削除の可否を返す（管理者）
         * （管理者の場合、公開申請済、公開承認済、一般公開中、取下公開中のいずれかのステータスの場合false）
         */
        isAdministratorProhibitedToDelete(){
            return function(item) {
                const statusAbleToDelete = [2, 3, 4, 5]
                return !this.isAdministrator || !statusAbleToDelete.includes(item.status)
            }
        },
        /**
         * 機器の取下の可否を返す（事業者のみ）
         * （ステータスが公開申請済・公開承認済・一般公開中のいずれかかつ機器を表示している事業者がブランド事業者であり、
         * さらにまだ取下げフラグがfalseである場合はfalse）
         */
        isProhibitedToCancel(){
            return function(item) {
                return !this.isOrganization || (item.status !== 2 && item.status !== 3 && item.status !== 4) || !this.isBrandUser(item) || item.canceled === true
            }
        },
        /**
         * 機器の承認の可否を返す（管理者のみ）
         * （ステータスが公開申請済である場合false）
         */
        isProhibitedToApprove(){
            return function(item) {
                return !this.isAdministrator || item.status !== 2
            }
        },
        /**
         * 機器のブランド事業者IDがログイン中の事業者IDと一致するか否か
         */
        isBrandUser() {
            return function (item) {
                return item.brand_name.organization_id == this.organization.id
            }
        },
        /**
         * 事業者ページか否か
         */
        isOrganization() {
            return this.$route.meta.routeType === 'organization'
        },
        /**
         * 管理者ページか否か
         */
        isAdministrator() {
            return this.$route.meta.routeType === 'administrator'
        },
        vModelLabel(){
            const key = Object.keys(this.master.devices_status).filter(item => {
                return this.master.devices_status[item].english === this.status
            })
            // key[0]がない時はデフォルトの選択肢が出るが、戻り値が出せずにエラーになるのを防いでおく
            return key[0] ? this.master.devices_status[key[0]].japanese : ''
        },
        devicesStatus(){
            if (this.isAdministrator) {
                return this.master.devices_status
            } else {
                const devicesStatus = cloneDeep(this.master.devices_status)
                delete devicesStatus[6]
                return devicesStatus
            }
        },
        start(){
            return 1 + (this.page - 1) * pageContentCount
        },
        end(){
            const count = this.page * pageContentCount
            return count < this.total ? count : this.total
        }
    },
    watch:{
        date_type(value){
            if(isSet(value) === false) {
                this.start_date = null
                this.finish_date = null
            }
        }
    },
    async mounted(){
        scroll(0,0)
        const loader = this.$loading.show()
        let params = {include_expired:true}
        if (this.isOrganization) {
            params.brand_name = null
        }
        // updateが完了してから検索させたい
        await this.update(params)
        await Promise.all([
            this.searchHybridWaterHeaters({page:this.page, publicFlag:false}),
            this.getBrandNames(false)
        ])
        loader.hide()
    },
    methods:{
        ...mapActions({
            searchHybridWaterHeaters:'hybridWaterHeaters/search',
            kenkenDownload:'hybridWaterHeaters/kenkenDownload',
            kenkenCertificateDownload:'hybridWaterHeaters/kenkenCertificateDownload',
            csvDownload:'hybridWaterHeaters/organizationDownload',
            update:'hybridWaterHeaters/updateSearchHybridWaterHeatersData',
            fileUpdate:'file/update',
            clearError:'error/clear',
            getBrandNames:'hybridWaterHeaters/getBrandNames',
        }),
        /**
         * 機器の検索を実行
         */
        async search() {
            const loader = this.$loading.show()
            // updateが完了してから検索させたい
            await this.update({include_expired:true})
            await this.searchHybridWaterHeaters({page:1, publicFlag:false})
            loader.hide()
        },
        /**
         * 建研用ダウンロードを実行（管理者のみ）
         */
        async adminDownload(){
            const loader = this.$loading.show()
            await Promise.all([
                this.kenkenDownload(),
                this.kenkenCertificateDownload(),
            ])
            loader.hide()
        },
        /**
         * アップロード画面に遷移（事業者のみ）
         */
        upload(){
            this.fileUpdate({
                fileName:null,
                file:{},
                uploadDisabled:false
            })
            this.clearError()
            move(this.$router, '/organization/hybrid_water_heaters/upload')
        },
        /**
         * 機器の詳細画面に遷移
         */
        toDetail(item){
            this.clearError()
            let url
            if (this.isOrganization) {
                url = '/organization/hybrid_water_heaters/' + item.id
            } else if (this.isAdministrator) {
                url = '/admin/hybrid_water_heaters/' + item.id
            }
            move(this.$router, url)
        },
        /**
         * 機器の削除画面に遷移
         */
        toDelete(item){
            this.clearError()
            let url
            if (this.isOrganization) {
                url = '/organization/hybrid_water_heaters/' + item.id + '/delete'
            } else if (this.isAdministrator) {
                url = '/admin/hybrid_water_heaters/' + item.id + '/delete'
            }
            move(this.$router, url)
        },
        /**
         * 機器の公開申請画面に遷移（事業者のみ）
         */
        toPublish(item){
            this.clearError()
            move(this.$router, '/organization/hybrid_water_heaters/' + item.id + '/status/published')
        },
        /**
         * 機器の取下画面に遷移（事業者のみ）
         */
        toCancel(item){
            this.clearError()
            move(this.$router, '/organization/hybrid_water_heaters/' + item.id + '/status/canceled')
        },
        /**
         * 機器の取下申請画面に遷移（事業者のみ）
         */
        toPublishAndCancel(item){
            this.clearError()
            move(this.$router, '/organization/hybrid_water_heaters/' + item.id + '/status/published/cancel')
        },
        /**
         * 機器の承認画面に遷移（管理者のみ）
         */
        toApprove(item){
            this.clearError()
            move(this.$router, '/admin/hybrid_water_heaters/' + item.id + '/approved/enabled')
        },
        /**
         * マウスホバーした箇所のidをdataに保存
         */
        over(id){
            this.hovered = id
        },
        /**
         * マウスホバーした箇所のid情報を削除
         */
        leave(){
            this.hovered = null
        },
        /**
         * 機器リストのページの切り替え
         */
        async pageChange(page){
            const loader = this.$loading.show()
            await this.searchHybridWaterHeaters({page, publicFlag:false})
            loader.hide()
        },
        isSet(prop){
            return isSet(prop)
        },
        async download(){
            const loader = this.$loading.show()
            await this.csvDownload()
            loader.hide()
        },
    }
}
</script>

<style scoped>
.lists_table{
    width: 100%;
}
table{
    border-collapse: collapse;
}
.buttons{
    position:absolute;
    top:-0.5rem;
    right:0;
    white-space: nowrap;
    width:auto;
    
}
.td-fixed{
    position: relative;
}
.bl_searchBox{
    min-width: 780px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
}
.bl_searchBox >>> .radio{
    display: inline-flex;
    align-items: center;
}
.bl_searchBox >>> .radio label{
    width: fit-content;
    padding-left: 1.75em;
}
.uq_date>label{
    width: 8em;
}
.uq_flexcolumn{
    display: flex;
    flex-direction: column;
    margin-right: 16px;
}
.uq_flexcolumn >>> .select_btn{
    min-width: 150px;
}
.uq_flexcolumn >>> .select_wrapper label{
    width: 100%;
}
.uq_flexcolumn >>> label{
    font-weight: 600;
    font-size: 12px;
}
.uq_flexcolumn >>> p{
    font-weight: 600;
    font-size: 12px;
}
.uq_flexcolumn>.bl_input_container .input_container:last-of-type{
    margin-bottom: 0;
}
.uq_flex_end{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    height: 100%;
}
.uq_flex_end >>> button.el_button {
    margin: 0;
}
.bl_searchBox>.bl_input_container:not(:last-of-type){
    margin-right: 16px;
    margin-bottom: 0;
}
.bl_searchBox .bl_input_container_column{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.bl_searchBox .uq_flexcolumn:nth-child(1) .formrow >>> .el_label{
    width: 8.5em;
}
.bl_searchBox .uq_flexcolumn:nth-child(2) .formrow >>> .el_label{
    width: 12em;
}

/* 管理者ログイン時 */
.isAdministrator .uq_flex_end{
    margin-top: auto;
    margin-left: auto;
    height: fit-content;
}
.isAdministrator .uq_flex_end >>> button{
    margin-top: 0;
}
.isAdministrator .uq_date{
    white-space: nowrap;
    flex-wrap: nowrap;
}
.isAdministrator .bl_searchBox{
    flex-wrap: wrap;
}

 /* タブレット縦よりウインドウ幅が狭いもの */
@media screen and (max-width: 1200px) {
    .bl_searchBox{
        flex-wrap: wrap;
    }
    .uq_flex_end{
        margin-top: 16px;
        margin-left: auto;
        height: fit-content;
    }
    .isAdministrator .bl_searchBox{
        width: 560px;
        min-width: 560px;
    }
    .isAdministrator .uq_flexcolumn:nth-last-child(2){
        margin-top: 8px;
    }
    .isAdministrator .bl_searchBox .uq_flexcolumn >>> .formrow .el_label,
    .isAdministrator .bl_searchBox >>> .formrow .el_label.rowlabel.grid1,
    .isAdministrator .bl_input_container_column >>> .el_label,
    .isAdministrator .uq_date .el_label{
        width: 12em;
    }
}
</style>